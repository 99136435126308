import { APIProvider, Map } from "@vis.gl/react-google-maps";

function App() {
  return (
    <APIProvider apiKey={"AIzaSyCvWMLaag9s-2s1hQAIvWXQoove1k_C-7Y"}>
      <Map
        renderingType="VECTOR"
        style={{ width: "100vw", height: "100vh" }}
        defaultZoom={10}
        defaultCenter={{ lat: -6.36088, lng: 106.85256 }}
      ></Map>
    </APIProvider>
  );
}

export default App;
